import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// project import
import Auth0Context from 'contexts/Auth0Context'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { GuardProps } from 'types/auth'

const AuthGuard = ({ children }: GuardProps) => {
  const { token, isLoggedIn } = useAuth()
  const { isError } = useProfile(token!)
  const navigate = useNavigate()
  const location = useLocation()
  const authContext = useContext(Auth0Context)
  const { logout } = authContext || {}

  useEffect(() => {
    if (!isLoggedIn) {
      if (logout) {
        console.log('Logging out')
        logout()
      } else {
        navigate('login', {
          state: {
            from: location.pathname,
          },
          replace: true,
        })
      }
    }
  }, [isLoggedIn, navigate, location, logout])

  useEffect(() => {
    if (isError) {
      if (logout) {
        console.log('Logging out')
        logout()
      } else {
        navigate('login', {
          state: {
            from: location.pathname,
          },
          replace: true,
        })
      }
    }
  }, [isError, navigate, location, logout])

  return children
}

export default AuthGuard
