import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { PeriodsResponse } from 'types/periods'
import { fetchWithToken } from './http'

export function usePeriods(token: string, organizationId: string | null, shouldFetch: boolean = true) {
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetchData = token != null && token.trim() !== '' && shouldFetch
  const params = new Array<[string, string]>()
  if (organizationId) {
    params.push(['organizationId', organizationId])
  }

  const { data, error, mutate } = useSWR(
    shouldFetchData ? [`periods`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params.length > 0 ? params : null),
    {
      suspense: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [shouldFetchData, mutate])

  const handleRefetch = () => {
    setIsLoading(true)
    mutate().finally(() => {
      setIsLoading(false)
    })
  }

  return {
    periods: data as PeriodsResponse,
    isLoadingPeriods: isLoading,
    isError: error,
    handleRefetch,
  }
}
