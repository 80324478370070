import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// google-fonts
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'

import ThemeCustomization from 'themes'

import Locales from 'components/Locales'
import ScrollTop from 'components/ScrollTop'

import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context'
import { GlobalStateProvider } from 'contexts/GlobalStateContext'

import { ConfigProvider } from 'contexts/ConfigContext'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')
const root = createRoot(container!)

// Register the service worker
// the prompting for reload should be removed and we should just reload the page when a
// new version is available. this prompt is here for testing purposes. We will see if
// this works in production.
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting && window.confirm('New version available! Do you want to reload?')) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }
  },
})

root.render(
  <ConfigProvider>
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <BrowserRouter basename={process.env.REACT_APP_BASE_NAME || '/'}>
              <GlobalStateProvider>
                <App />
              </GlobalStateProvider>
            </BrowserRouter>
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  </ConfigProvider>
)

reportWebVitals()
