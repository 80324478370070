import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

// project import

import Snackbar from 'components/@extended/Snackbar'
import Notistack from 'components/third-party/Notistack'

import { useGlobalState } from 'contexts/GlobalStateContext'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import LoginRoutes from 'routes/LoginRoutes'
import MainRoutes from 'routes/MainRoutes'

const App = () => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const { purcentAppState, onChangeOrganizationSelection } = useGlobalState()

  useEffect(() => {
    let isMounted = true

    const getUser = async () => {
      if (!isMounted) return

      if (profile && profile.roles && profile.roles.length > 0) {
        if (purcentAppState!.selectedOrganization === null) {
          onChangeOrganizationSelection(profile.roles[0].organization || null)
        }
      }
    }

    getUser()

    return () => {
      isMounted = false
    }
  })

  const renderRoutes = (routes: typeof LoginRoutes | typeof MainRoutes) =>
    routes.children.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children?.map((child) => <Route key={child.path} path={child.path} element={child.element} />)}
      </Route>
    ))

  return (
    <>
      <Notistack>
        <Routes>
          {renderRoutes(LoginRoutes)}
          {renderRoutes(MainRoutes)}
        </Routes>
        <Snackbar />
      </Notistack>
    </>
  )
}

export default App
