import { MenuItem, Select, useTheme } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import React, { FC, useMemo, useState } from 'react'
import { ThemeMode } from 'types/config'
import { Period, parsePeriodDate } from 'types/periods'
import './SinglePeriodSelector.css'

interface SinglePeriodSelectorProps {
  periods: Period[]
  onChange: (periodId: string) => void
  value?: string
  rootPeriodOnly?: boolean
  sortOrder?: 'asc' | 'desc'
  timeFilter?: 'pastOnly' | 'futureOnly' | 'standard'
  className?: string
  style?: React.CSSProperties
  width?: string | number
  label?: string
  disabled?: boolean
}

const SinglePeriodSelector: FC<SinglePeriodSelectorProps> = ({
  periods,
  onChange,
  value,
  rootPeriodOnly = false,
  sortOrder = 'asc',
  timeFilter = 'standard',
  className,
  style,
  width = '100%',
  label = 'Select Period',
  disabled = false,
}) => {
  const theme = useTheme()
  const apiRef = useTreeViewApiRef()
  const [open, setOpen] = useState(false)

  const filteredPeriods = useMemo(() => {
    const now = new Date()

    const filterPeriod = (period: Period): Period | null => {
      const startDate = parsePeriodDate(period.startDate)
      const endDate = parsePeriodDate(period.endDate)

      if (!startDate || !endDate) {
        return period // Include periods with invalid dates
      }

      let result: boolean
      switch (timeFilter) {
        case 'pastOnly':
          result = startDate <= now
          break
        case 'futureOnly':
          result = startDate > now
          break
        default:
          result = true
      }

      if (!result) return null

      if (period.children) {
        const filteredChildren = period.children.map(filterPeriod).filter((child): child is Period => child !== null)
        return { ...period, children: filteredChildren }
      }

      return period
    }

    const filtered = periods.map(filterPeriod).filter((period): period is Period => period !== null)

    return filtered
  }, [periods, timeFilter])

  const sortedPeriods = useMemo(() => {
    return [...filteredPeriods].sort((a, b) => {
      const dateA = parsePeriodDate(a.startDate)
      const dateB = parsePeriodDate(b.startDate)
      return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime()
    })
  }, [filteredPeriods, sortOrder])

  const renderTree = (nodes: Period[]): TreeViewBaseItem[] =>
    nodes.map((node) => ({
      id: node.id ?? '',
      label: node.label || '', // Use only the current node's label
      children: !rootPeriodOnly && node.children ? renderTree(node.children) : [],
    }))

  const treeItems = useMemo(() => renderTree(sortedPeriods), [sortedPeriods, rootPeriodOnly])

  const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    if (isSelected) {
      onChange(itemId)
      setOpen(false)
    }
  }

  const getSelectedPeriodLabel = () => {
    const findPeriod = (
      periods: Period[],
      rootLabel?: string,
      depth = 0
    ): { period: Period; rootLabel: string; depth: number } | undefined => {
      for (const period of periods) {
        if (period.id === value) {
          return { period, rootLabel: rootLabel || period.label, depth }
        }
        if (period.children) {
          const found = findPeriod(period.children, rootLabel || period.label, depth + 1)
          if (found) return found
        }
      }
      return undefined
    }

    const result = findPeriod(sortedPeriods)
    if (result) {
      const { period, rootLabel, depth } = result
      return depth === 0 ? period.label : `${period.label} (${rootLabel})`
    }
    return label
  }

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  return (
    <div className={className} style={{ ...style, marginBottom: 0, paddingBottom: 0, width: width }}>
      <Select
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        renderValue={getSelectedPeriodLabel}
        variant="standard"
        style={{ width: '100%' }}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        disabled={disabled}
      >
        <MenuItem value={value} style={{ display: 'none' }}></MenuItem>
        <RichTreeView
          multiSelect={false}
          checkboxSelection
          style={{ width: '100%' }}
          apiRef={apiRef}
          items={treeItems}
          selectedItems={value}
          onItemSelectionToggle={handleItemSelectionToggle}
          defaultExpandedItems={treeItems.length > 0 ? [treeItems[0].id] : []}
          sx={{
            '& .MuiTreeItem-content:hover': {
              backgroundColor: hoverColor,
            },
            '& .MuiTreeItem-content.Mui-selected:hover': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        />
      </Select>
    </div>
  )
}

export default SinglePeriodSelector
