import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import { ChangeEvent, FC, startTransition, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KeyedObject } from 'types/root'
import { getOrganizationLabelMap } from 'types/user-profile'
import { wholePercentFormatter } from 'utils/formatting'
import { useLocalStorage } from 'utils/useLocalStorage'
interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

interface TemplatesTableProps {
  withCreateButtons?: boolean
  deleteTemplate: (token: string, id: string) => Promise<{ message: string }>
}

const TemplatesTable: FC<TemplatesTableProps> = ({ withCreateButtons = true, deleteTemplate }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [page, setPage] = useLocalStorage<number>('templates-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('templates-rows-per-page', 10)
  const [search, setSearch] = useLocalStorage<string>('templates-search', '')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'templates-selected-organizations',
    []
  )
  const [sort, setSort] = useLocalStorage<string>('templates-table-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('templates-table-order', 'asc')
  const tableContainerRef = useRef(null)

  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const { templates, isLoading, refetch } = useSearchOrganizationTemplates(token!, selectedOrganizations, {
    searchTerm: search,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  })

  useEffect(() => {
    if (tableContainerRef.current) {
      ;(tableContainerRef.current as HTMLElement).scrollTop = 0
    }
  }, [sort, order, page, rowsPerPage])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  useEffect(() => {}, [templates, profile])

  // table columns
  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    {
      id: 'organizationIds',
      label: 'Teams',
      minWidth: 170,
      format: (value) => {
        if (Array.isArray(value) && value.length >= 4) {
          return `(${value.length}) Teams`
        }

        return Array.isArray(value) ? value.map((id) => organizationMap[id]).join(', ') : organizationMap[value]
      },
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variable_percent',
      label: 'Variable Compensation',
      minWidth: 140,
      format: (value: any, row: any) => wholePercentFormatter.format(row.variablePercent),
    },
    {
      id: 'variableCap',
      label: 'Max Payout',
      minWidth: 170,
      format: (value) => {
        return wholePercentFormatter.format(value)
      },
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 180, // Increased to accommodate the new button
      format: (templateId: string, row: any) => (
        <Box display="flex">
          <Tooltip title="Duplicate Template">
            <IconButton
              color="primary"
              onClick={() =>
                startTransition(() => {
                  navigate(`/admin/templates/${templateId}/copy`)
                })
              }
            >
              <CopyOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() =>
                startTransition(() => {
                  navigate(`/admin/templates/${templateId}/edit`)
                })
              }
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          {withCreateButtons && (
            <Tooltip title="Create Plan from this Template">
              <IconButton color="primary" onClick={() => navigate(`/create-comp-plan?templateId=${templateId}`)}>
                <PlusCircleOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete Template">
            <IconButton color="error" onClick={() => handleDeleteClick(templateId, row.label)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState<{ id: string; name: string } | null>(null)

  const handleDeleteClick = (templateId: string, templateName: string) => {
    setTemplateToDelete({ id: templateId, name: templateName })
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (templateToDelete && token) {
      deleteTemplate(token, templateToDelete.id)
        .then(() => {
          setDeleteDialogOpen(false)
          setTemplateToDelete(null)
          refetch() // Trigger a re-fetch of the templates
        })
        .catch((error) => {
          console.error('Failed to delete template:', error)
          // Handle error (e.g., show error message to user)
        })
    }
  }

  return (
    <Box>
      <SimpleTitle title="Templates" />
      <Grid container spacing={4} mb={3}>
        <Grid item xs={3}>
          <SearchInput key="search-input" onSearch={handleSearch} initialValue={search} />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={3}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={setSelectedOrganizations}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Reset Filters">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedOrganizations([])
                setSearch('')
              }}
              sx={{ mt: 2 }}
            >
              <CloseCircleOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex', // Enables flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              height: '100%', // Takes full height of the container
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          {/* table */}
          <TableContainer ref={tableContainerRef} sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
            <Table key="templates-table" stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      id={'cell-col-' + (column.id || '')}
                      sx={{
                        minWidth: column.minWidth,
                        position: 'sticky !important',
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.id !== 'label' && column.id !== 'position' ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort === column.id}
                          direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.length > 0 ? (
                  templates.map((row: KeyedObject) => (
                    <TableRow
                      id={'table-row-' + row.id}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'table-row-' + row.id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell id={'cell-' + column.id} key={'cell-' + column.id} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
            {withCreateButtons ? (
              <AnimateButton>
                <Tooltip title="Create a new template">
                  <Button
                    variant="contained"
                    startIcon={<PlusOutlined />}
                    color="primary"
                    onClick={() =>
                      startTransition(() => {
                        navigate('/admin/templates/create')
                      })
                    }
                  >
                    Template
                  </Button>
                </Tooltip>
              </AnimateButton>
            ) : (
              <div />
            )}
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={templates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-template-dialog"
        aria-describedby="delete-template-description"
      >
        <DialogTitle id="delete-template-dialog">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-template-description">
            Are you sure you want to delete{' '}
            <Typography component="span" color="primary" fontWeight="bold" display="inline">
              {templateToDelete?.name}
            </Typography>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TemplatesTable
