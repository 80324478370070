import dayjs from 'dayjs'
import React from 'react'

import { VariableAllocationRow, VariableAllocationTable } from 'types/comp-allocations'
import { CompensationPlan, ExpandedCompensationPlan } from 'types/comp-plan'
import { ExpandedCompensationTemplate } from 'types/comp-template'
import { TargetDTO } from 'types/components'

interface CreateplanDataWrapperProps {
  compensationTemplate?: ExpandedCompensationTemplate
  existingPlan?: ExpandedCompensationPlan
  showOriginalEffectiveDate?: boolean
  withUserId?: string // Add this line
  children: React.ReactNode
}

interface CreatePlanContextValue {
  compensationTemplate: ExpandedCompensationTemplate | null | undefined
  templateComponents: VariableAllocationTable[]
  setTemplateComponents: any
  templateComponentsInPlan: VariableAllocationTable[]
  setTemplateComponentsInPlan: any
  plan: CompensationPlan
  setPlan: any
  validPlanConfig: boolean
  setValidPlanConfig: any
  mode: 'CREATE' | 'EDIT' | 'REVIEW'
  setMode: any
  totalComponentsInPlanValue: number
  maxVariableAllowedInComponents: number
  employeeName: string
  setEmployeeName: any
  setMaxVariableAllowedInComponents: any
  targetDTOs: TargetDTO[]
  setTargetDTOs: any
  originalEffectiveDate: string | null
  showOriginalEffectiveDate: boolean
  withUserId?: string // Add this line
}

const CreatePlanContext = React.createContext<CreatePlanContextValue>({
  compensationTemplate: null,
  templateComponents: [],
  setTemplateComponents: () => {},
  templateComponentsInPlan: [],
  setTemplateComponentsInPlan: () => {},
  plan: {
    id: null,
    revision: 0,
    userId: '',
    templateId: null,
    templateRevision: null,
    organizationId: '',
    rootOrganizationId: '',
    rootPeriodId: '',
    periodId: '',
    periodProration: 1.0,
    position: '',
    basePlus: false,
    baseSalary: 0,
    variablePercent: 0.0,
    variableCap: 0,
    variableAllocations: [],
    effectiveDate: '',
    createdAt: '',
    updatedAt: '',
  },
  setPlan: () => {},
  validPlanConfig: false,
  setValidPlanConfig: () => {},
  mode: 'CREATE',
  setMode: () => {},
  totalComponentsInPlanValue: 0,
  maxVariableAllowedInComponents: 0,
  employeeName: '',
  setEmployeeName: () => {},
  setMaxVariableAllowedInComponents: () => {},
  targetDTOs: [],
  setTargetDTOs: () => {},
  originalEffectiveDate: null,
  showOriginalEffectiveDate: false,
  withUserId: undefined, // Add this line
})

const CreatePlanContextProvider: React.FC<CreateplanDataWrapperProps> = ({
  compensationTemplate,
  existingPlan,
  showOriginalEffectiveDate,
  withUserId, // Add this line
  children,
}) => {
  const tempPlan: CompensationPlan = React.useMemo(() => {
    if (existingPlan) {
      return {
        ...existingPlan.plan,
        effectiveDate: existingPlan.plan.effectiveDate,
        showOriginalEffectiveDate: showOriginalEffectiveDate,
      }
    } else if (compensationTemplate) {
      return {
        id: null,
        revision: 0,
        userId: '',
        templateId: compensationTemplate.template.id || null,
        templateRevision: compensationTemplate.template.revision || null,
        organizationId: compensationTemplate.template.rootOrganizationId,
        rootOrganizationId: compensationTemplate.template.rootOrganizationId,
        rootPeriodId: compensationTemplate.template.rootPeriodId,
        periodId: compensationTemplate.template.periodId,
        periodProration: compensationTemplate.template.periodProration,
        position: compensationTemplate.template.position,
        basePlus: compensationTemplate.template.basePlus,
        baseSalary: 0.0,
        variablePercent: compensationTemplate.template.variablePercent,
        variableCap: compensationTemplate.template.variableCap,
        variableAllocations: compensationTemplate.template.variableAllocations,
        effectiveDate: '',
        showOriginalEffectiveDate: showOriginalEffectiveDate,
        createdAt: '',
        updatedAt: '',
      } as CompensationPlan
    } else {
      // Provide a default plan if neither existingPlan nor compensationTemplate is provided
      return {
        id: null,
        revision: 0,
        userId: '',
        templateId: null,
        templateRevision: null,
        organizationId: '',
        rootOrganizationId: '',
        rootPeriodId: '',
        periodId: '',
        periodProration: 1.0,
        position: '',
        basePlus: false,
        baseSalary: 0.0,
        variablePercent: 0,
        variableCap: 0,
        variableAllocations: [],
        effectiveDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        showOriginalEffectiveDate: showOriginalEffectiveDate,
        createdAt: '',
        updatedAt: '',
      }
    }
  }, [existingPlan, compensationTemplate, showOriginalEffectiveDate])

  const [plan, setPlan] = React.useState<CompensationPlan>(tempPlan)

  const [templateComponentsInPlan, setTemplateComponentsInPlan] = React.useState<VariableAllocationTable[]>(() => {
    if (existingPlan?.plan?.variableAllocations) {
      return existingPlan.plan.variableAllocations.map((category) => ({ label: category.label, rows: category.rows }))
    } else if (compensationTemplate?.template?.variableAllocations) {
      return compensationTemplate.template.variableAllocations.map((category) => ({ label: category.label, rows: [] }))
    }
    return []
  })

  const [templateComponents, setTemplateComponents] = React.useState<VariableAllocationTable[]>(() => {
    if (existingPlan?.plan?.variableAllocations) {
      return JSON.parse(JSON.stringify(existingPlan.plan.variableAllocations))
    } else if (compensationTemplate?.template?.variableAllocations) {
      return JSON.parse(JSON.stringify(compensationTemplate.template.variableAllocations))
    }
    return []
  })

  const [totalComponentsInPlanValue, setTotalComponentsInPlanValue] = React.useState<number>(0)
  const [maxVariableAllowedInComponents, setMaxVariableAllowedInComponents] = React.useState<number>(0)
  const [mode, setMode] = React.useState<'CREATE' | 'EDIT' | 'REVIEW'>('CREATE')
  const [validPlanConfig, setValidPlanConfig] = React.useState<boolean>(false)
  const [employeeName, setEmployeeName] = React.useState<string>('')

  const [targetDTOs, setTargetDTOs] = React.useState<TargetDTO[]>(() => {
    if (existingPlan?.targets) {
      return existingPlan.targets.map((target) => ({
        componentId: target.componentId,
        label: target.label,
        value: target.value,
        rootOrganizationId: target.rootOrganizationId,
        organizationId: target.organizationId,
        rootPeriodId: target.rootPeriodId,
        periodId: target.periodId,
        slug: target.slug,
        userId: target.userId,
      }))
    } else if (compensationTemplate?.targets) {
      return compensationTemplate.targets.map((target) => ({
        componentId: target.componentId,
        label: target.label,
        value: target.value,
        rootOrganizationId: target.rootOrganizationId,
        organizationId: target.organizationId,
        rootPeriodId: target.rootPeriodId,
        periodId: target.periodId,
        slug: target.slug,
        userId: null,
      }))
    }
    return []
  })

  const [originalEffectiveDate, setOriginalEffectiveDate] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (existingPlan?.plan?.effectiveDate) {
      setOriginalEffectiveDate(existingPlan.plan.effectiveDate)
    }
  }, [existingPlan])

  React.useEffect(() => {
    const validatePlanConfig = (allocationTotal: number): boolean => {
      if (
        allocationTotal === 100 &&
        plan.userId !== '' &&
        plan.baseSalary > 0 &&
        plan.variablePercent > 0 &&
        plan.variableCap >= 0 &&
        plan.variablePercent <= plan.variableCap
      ) {
        return true
      } else {
        return false
      }
    }

    let allocationTotal = 0
    let maxVariableAllowed = 0

    if (plan.variableAllocations) {
      plan.variableAllocations.forEach((table) => {
        table.rows.forEach((row: VariableAllocationRow) => {
          const disabled = row.disabled != undefined && row.disabled != null && row.disabled
          if (row.rowType !== 'DISPLAY_ROW' && !disabled) {
            allocationTotal += row.allocationPercentage * 100
            const maxPayout =
              row.maxAllocationPercentage != null && row.maxAllocationPercentage != 0
                ? plan.baseSalary * row.maxAllocationPercentage * plan.variablePercent
                : plan.baseSalary * row.allocationPercentage * plan.variablePercent

            maxVariableAllowed += maxPayout
          }
        })
      })
    }

    setTotalComponentsInPlanValue(allocationTotal)
    setMaxVariableAllowedInComponents(maxVariableAllowed)
    setValidPlanConfig(validatePlanConfig(allocationTotal))
  }, [templateComponentsInPlan, plan])

  const value: CreatePlanContextValue = {
    compensationTemplate: compensationTemplate || null,
    templateComponents,
    setTemplateComponents,
    templateComponentsInPlan,
    setTemplateComponentsInPlan,
    plan,
    setPlan,
    validPlanConfig,
    setValidPlanConfig,
    mode,
    setMode,
    totalComponentsInPlanValue,
    maxVariableAllowedInComponents,
    employeeName,
    setEmployeeName,
    setMaxVariableAllowedInComponents,
    targetDTOs,
    setTargetDTOs,
    originalEffectiveDate,
    showOriginalEffectiveDate: showOriginalEffectiveDate || false,
    withUserId, // Add this line
  }

  return <CreatePlanContext.Provider value={value}>{children}</CreatePlanContext.Provider>
}

const useCreatePlanContext = () => {
  const context = React.useContext(CreatePlanContext)
  if (context === undefined) {
    throw new Error('useCreatePlanContext must be used within a CreatePlanContextProvider')
  }
  return context
}

export { CreatePlanContextProvider, useCreatePlanContext }
