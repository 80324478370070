import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import React, { useEffect, useState } from 'react'
import { LedgerAccount, LedgerAccountDTO } from 'types/ledger-account'

interface LedgerAccountDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (account: LedgerAccountDTO) => void
  account: LedgerAccount | null
  organizationId: string
  isLoading: boolean
}

const LedgerAccountDialog: React.FC<LedgerAccountDialogProps> = ({
  open,
  onClose,
  onSubmit,
  account,
  organizationId,
  isLoading,
}) => {
  const [formData, setFormData] = useState<LedgerAccountDTO>({
    organizationId: '',
    externalId: '',
    label: '',
  })

  useEffect(() => {
    if (open) {
      setFormData(account ? { ...account } : { organizationId, externalId: '', label: '' })
    }
  }, [open, account, organizationId])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    onSubmit(formData)
    // The dialog will be closed by the parent component after successful submission
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{account ? 'Edit Ledger Account' : 'Add Ledger Account'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="externalId"
          label="External ID"
          type="text"
          variant="standard"
          fullWidth
          value={formData.externalId}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="label"
          label="Label"
          type="text"
          variant="standard"
          fullWidth
          value={formData.label}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <AnimateButton>
          <Button variant="contained" color="warning" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
        </AnimateButton>

        <Tooltip title={account ? 'Update' : 'Create'} arrow placement="top-start">
          <span>
            <AnimateButton>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : account ? 'Update' : 'Create'}
              </Button>
            </AnimateButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

export default LedgerAccountDialog
