import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useSearchTargets } from 'hooks/useComponents'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { TargetSearchResponse } from 'types/components'
import { createIdLabelMap } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'
import { useLocalStorage } from 'utils/useLocalStorage'
import TargetsTable from '../TargetsTable'

const ManageTargets: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'targets-selected-organizations',
    []
  )
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('targets-selected-periods', [])
  const [periodLabelMap, setPeriodLabelMap] = useState<{ [key: string]: string }>({})
  const [organizationLabelMap, setOrganizationLabelMap] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [targets, setTargets] = useState<TargetSearchResponse>({
    targets: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })
  const [page, setPage] = useLocalStorage<number>('targets-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('targets-rows-per-page', 25)
  const [sort, setSort] = useLocalStorage<string>('targets-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('targets-order', 'asc')

  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods, isLoadingPeriods } = usePeriods(token!, rootOrganizationId, rootOrganizationId !== '')

  const {
    targets: targetsRsp,
    isLoading: isLoadingTargets,
    refetch: refetchTargets,
  } = useSearchTargets(token!, selectedOrganizations, selectedPeriods, {
    searchTerm: searchTerm,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchTargets()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Initial data fetch
    refetchTargets()

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    setIsLoading(isLoadingTargets || isLoadingPeriods || isLoadingProfile)
  }, [isLoadingTargets, isLoadingPeriods, isLoadingProfile])

  useEffect(() => {
    if (targetsRsp) {
      setTargets(targetsRsp)
    }
  }, [targetsRsp])

  useEffect(() => {
    if (periods) {
      const newPeriodLabelMap =
        periods && periods.periods
          ? periods.periods.reduce((acc: { [key: string]: string }, period) => {
              const idLabelMap = createIdLabelMap(period)
              Object.entries(idLabelMap).forEach(([id, label]) => {
                const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
                acc[id] = newLabel
              })
              return acc
            }, {})
          : {}

      setPeriodLabelMap(newPeriodLabelMap)
    }
  }, [periods])

  useEffect(() => {
    if (profile) {
      const newOrganizationLabelMap = getOrganizationLabelMap(profile)
      setOrganizationLabelMap(newOrganizationLabelMap)
      // find the first root organization
      const org = profile.roles.find((role) => role.organization.rootOrganizationId != null)
      if (org) {
        setRootOrganizationId(org.organization.rootOrganizationId!)
      }
    }
  }, [profile])

  useEffect(() => {}, [selectedOrganizations, selectedPeriods, searchTerm])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
    setPage(0)
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    setSelectedPeriods(newPeriods)
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  return (
    <Box>
      <SimpleTitle title="Targets" />
      <Grid container spacing={4} mb={3}>
        <Grid item xs={3}>
          <SearchInput key="targets-search-input" onSearch={handleSearch} initialValue={searchTerm} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={handleOrganizationChange}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiPeriodSelector
            periods={periods != null ? periods.periods : []}
            selectedPeriods={selectedPeriods}
            handleChange={handlePeriodChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Reset Filters">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedOrganizations([])
                setSelectedPeriods([])
                setSearchTerm('')
              }}
              sx={{ mt: 2 }}
            >
              <CloseCircleOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <TargetsTable
          selectedOrganizations={selectedOrganizations}
          selectedPeriods={selectedPeriods}
          searchTerm={searchTerm}
          periodLabelMap={periodLabelMap}
          organizationLabelMap={organizationLabelMap}
          targets={targets}
          page={page}
          rowsPerPage={rowsPerPage}
          sort={sort}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSort={handleSort}
        />
      )}
    </Box>
  )
}

export default ManageTargets
