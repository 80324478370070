import useSWR from 'swr'
import { CompensationTemplate, ExpandedCompensationTemplate } from 'types/comp-template'
import { fetchWithToken } from './http'
import { SearchParams, generateSearchQueryParams } from 'types/search-params'
import { PaginationInfo } from 'types/common'

export function useFetchTemplate(token: string, templateId: string | undefined, params: [string, string][] | null) {
  const { data, error, isLoading } = useSWR(
    templateId ? [`compensation/templates/${templateId}`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    compensationTemplate: data ? (data as ExpandedCompensationTemplate) : undefined,
    isLoading,
    isError: error,
  }
}

export function useFetchOrganizationTemplates(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/templates/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    templates: data?.templates ? (data?.templates as ExpandedCompensationTemplate[]) : [],
    isLoading,
    isError: error,
  }
}

export function useSearchOrganizationTemplates(token: string, organizationIds: string[], searchParams: SearchParams) {
  let params = generateSearchQueryParams(searchParams)
  params.push(['organizationIds', organizationIds.join(',')])

  const { data, error, isLoading, mutate } = useSWR(
    [`compensation/templates/organizations`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  const refetch = () => mutate()

  return {
    templates: data?.templates ? (data?.templates as CompensationTemplate[]) : [],
    pagination: data?.paginationInfo as PaginationInfo,
    isLoading,
    isError: error,
    refetch,
  }
}
