import { UserRole } from './role'
import { User } from './user'

export interface UserProfile {
  user: User
  roles: UserRole[]
}

export const getOrganizationLabelMap = (userProfile: UserProfile): Record<string, string> => {
  return userProfile.roles.reduce(
    (acc: Record<string, string>, role) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )
}

export const getRootOrganizationId = (userProfile: UserProfile): string | null => {
  if (userProfile.roles.length > 0) {
    return userProfile.roles[0].organization.rootOrganizationId
  }
  return null
}

export const getTopParentOrganizationId = (userProfile: UserProfile): string | null => {
  const rootOrg = userProfile.roles.find((role) => role.organization.rootOrganizationId === role.organization.id)
  return rootOrg ? rootOrg.organization.id : null
}

export const getOrganizationIdsWithPermissions = (
  userProfile: UserProfile,
  requiredPermissions: string[]
): string[] => {
  if (
    userProfile === null ||
    userProfile === undefined ||
    userProfile.roles === null ||
    userProfile.roles === undefined
  ) {
    return []
  }

  return userProfile.roles.reduce((acc: string[], role) => {
    const hasAllPermissions = requiredPermissions.every((permission) => role.role.permissions.includes(permission))

    if (hasAllPermissions && !acc.includes(role.organization.id)) {
      acc.push(role.organization.id)
    }

    return acc
  }, [])
}

export const getUniqueOrganizationRoles = (userProfile: UserProfile, memberRolesOnly = false): UserRole[] => {
  const priorityRoles = ['Super-Admin', 'Admin', 'Sub-Admin', 'Member', 'Guest']

  const roles = memberRolesOnly ? userProfile.roles.filter((role) => role.role.name === 'Member') : userProfile.roles

  // Create a map to store the highest priority role for each organization
  const orgRoleMap = roles.reduce((acc: Record<string, UserRole>, role) => {
    const orgId = role.organization.id
    const currentPriority = priorityRoles.indexOf(role.role.name)
    const existingRole = acc[orgId]

    // If no existing role for this org, or current role has higher priority (lower index)
    if (!existingRole || priorityRoles.indexOf(existingRole.role.name) > currentPriority) {
      acc[orgId] = role
    }

    return acc
  }, {})

  // Convert map back to array
  return Object.values(orgRoleMap)
}

export const getMemberRole = (userProfile: UserProfile): UserRole | null => {
  return userProfile.roles.find((role) => role.role.name === 'Member') || null
}
