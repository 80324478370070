import { Box, Grid, InputLabel, Slider, Stack, Switch, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Fragment, useEffect, useState } from 'react'

import { NumericFormat } from 'react-number-format'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import CompensationChart from '../../components/CompensationChart'

const CompensationPlanDetailForm = () => {
  const theme = useTheme()
  const { compensationTemplate, plan, setPlan, employeeName, mode } = useCreatePlanContext()

  const [basePlus, setBasePlus] = useState<boolean>(plan.basePlus)
  const [displaySalary, setDisplaySalary] = useState<number>(
    plan.basePlus ? plan.baseSalary : plan.baseSalary / (1 - plan.variablePercent)
  )
  const [baseSalary, setBaseSalary] = useState<number>(plan.baseSalary)
  const [variablePercentage, setVariablePercentage] = useState<number>(plan.variablePercent)
  const [variableCompensation, setVariableCompensation] = useState<number>(0)
  const [totalCompensation, setTotalCompensation] = useState<number>(0)

  // Calculate initial variableCap as a currency value
  const [variableCap, setVariableCap] = useState<number>(plan.variableCap ?? 0)

  const getPeriodProration = (): number => {
    return plan.periodProration
  }

  const calculateCompensation = (basePlus: boolean, salary: number, variablePercentage: number) => {
    const periodProration = getPeriodProration()
    const bonusAmount = salary * variablePercentage
    const baseCompensation = basePlus ? salary : salary - bonusAmount
    const proratedBaseCompensation = baseCompensation * periodProration
    const proratedBonusAmount = basePlus
      ? proratedBaseCompensation * variablePercentage
      : salary * periodProration - proratedBaseCompensation

    return {
      baseCompensation: proratedBaseCompensation,
      variableCompensation: proratedBonusAmount,
      totalCompensation: proratedBaseCompensation + proratedBonusAmount,
    }
  }

  const updateCompensationValues = () => {
    const { baseCompensation, variableCompensation, totalCompensation } = calculateCompensation(
      basePlus,
      baseSalary,
      variablePercentage
    )
    setDisplaySalary(baseCompensation)
    setVariableCompensation(variableCompensation)
    setTotalCompensation(totalCompensation)
  }

  useEffect(() => {
    updateCompensationValues()
  }, [basePlus, baseSalary, variablePercentage, variableCap])

  useEffect(() => {
    updateCompensationValues()
  }, [])

  const onVariableIncludedChange = (value: boolean) => {
    setBasePlus(value)
    let tempPlan = { ...plan }
    tempPlan.basePlus = value
    setPlan(tempPlan)
  }

  const onVariablePercentageChange = (value: number) => {
    setVariablePercentage(value)
    let tempPlan = { ...plan }
    tempPlan.variablePercent = value
    setPlan(tempPlan)
  }

  const onSalaryChange = (floatValue: number) => {
    setBaseSalary(floatValue)
    let tempPlan = { ...plan }
    tempPlan.baseSalary = floatValue
    setPlan(tempPlan)
  }

  const onMaxVariableCompensationChange = (percentValue: number) => {
    let tempPlan = { ...plan }
    tempPlan.variableCap = percentValue
    setVariableCap(percentValue)
    setPlan(tempPlan)
  }

  const percentFormatter = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })

  return (
    <Fragment>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <CompensationSummaryCard
            key="compensation-summary-card"
            color={theme.palette.primary.main}
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
            variableCap={plan.variableCap}
            period={compensationTemplate?.period}
            periodId={plan.periodId}
          />
        </Grid>
        <Grid item container xs={12}>
          <Box sx={{ mb: 2, mt: 2 }}>
            <Stack direction="row" spacing={2}>
              <Typography>Variable Included</Typography>
              <Switch
                key="variable-included-switch"
                disabled={mode === 'REVIEW'}
                checked={basePlus}
                onChange={(event) => {
                  onVariableIncludedChange(event.target.checked)
                }}
              />
              <Typography>Base Plus</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <InputLabel id="employee-base-salary-label">
                    {basePlus ? 'Annual Base Salary' : 'Expected Total Annual Earnings'}
                  </InputLabel>
                  <NumericFormat
                    key="base-salary-input"
                    value={baseSalary}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    customInput={TextField}
                    onValueChange={(values: any) => {
                      const { floatValue } = values
                      onSalaryChange(floatValue)
                    }}
                    isAllowed={(values: any) => {
                      const { floatValue } = values
                      return floatValue >= 0
                    }}
                    InputProps={{
                      readOnly: mode === 'REVIEW',
                    }}
                    variant="standard"
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel id="bonus-percentage-label">Variable Compensation Percentage</InputLabel>
                  <Box marginTop={2} sx={{ pl: 2, pr: 2 }}>
                    <Slider
                      key="variable-percentage-slider"
                      disabled={mode === 'REVIEW'}
                      onChange={(event, value) => {
                        onVariablePercentageChange((value as number) / 100)
                      }}
                      sx={{ mt: 2.5 }}
                      aria-label="Always visible"
                      value={variablePercentage * 100}
                      min={(compensationTemplate?.template.minVariablePercent ?? 0) * 100 || 0}
                      max={(compensationTemplate?.template.maxVariablePercent ?? 0) * 100 || 100}
                      step={0.5}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => {
                        const formattedValue = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format((baseSalary * (value as number)) / 100)
                        return formattedValue
                      }}
                      marks={[
                        {
                          value: (compensationTemplate?.template.minVariablePercent ?? 0) * 100,
                          label: percentFormatter.format(compensationTemplate?.template.minVariablePercent ?? 0),
                        },
                        {
                          value: (compensationTemplate?.template.maxVariablePercent ?? 0) * 100,
                          label: percentFormatter.format(compensationTemplate?.template.maxVariablePercent ?? 0),
                        },
                        {
                          value: variablePercentage * 100,
                          label: `${(variablePercentage * 100).toFixed(1)}%`,
                        },
                      ]}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <InputLabel id="employee-max-variable-comp-label">Maximum Payout</InputLabel>
                  <Box marginTop={2} sx={{ pl: 2, pr: 2 }}>
                    <Slider
                      key={`variable-cap-slider`}
                      disabled={mode === 'REVIEW'}
                      onChange={(event, value) => {
                        const newVariableCapPercentage = (value as number) / 100 // Convert to decimal percentage
                        onMaxVariableCompensationChange(newVariableCapPercentage) // Pass the percentage in decimal form
                      }}
                      sx={{ mt: 2.5 }}
                      aria-label="Always visible"
                      value={(variableCap ?? 0) * 100} // Convert decimal percentage to whole number for the slider
                      min={(compensationTemplate?.template.minVariableCap ?? 0) * 100 ?? 0}
                      max={(compensationTemplate?.template.maxVariableCap ?? 0) * 100 ?? 100}
                      step={0.5}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => {
                        const formattedValue = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format((baseSalary * (value as number) * variablePercentage) / 100)
                        return formattedValue
                      }}
                      marks={[
                        {
                          value: (compensationTemplate?.template.minVariableCap ?? 0) * 100,
                          label: `${((compensationTemplate?.template.minVariableCap ?? 0) * 100).toFixed(1)}%`,
                        },
                        {
                          value: (compensationTemplate?.template.maxVariableCap ?? 0) * 100,
                          label: `${((compensationTemplate?.template.maxVariableCap ?? 0) * 100).toFixed(1)}%`,
                        },
                        {
                          value: variableCap * 100,
                          label: `${(variableCap * 100).toFixed(1)}%`,
                        },
                      ]}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4} alignItems={'start'}>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <InputLabel id="employee-max-variable-comp-label">On Target Total Compensation</InputLabel>
                  <NumericFormat
                    key="total-compensation-input"
                    value={totalCompensation}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    InputProps={{
                      readOnly: true,
                    }}
                    customInput={TextField}
                    variant="standard"
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel id="employee-max-variable-comp-label">Base Compensation</InputLabel>
                  <NumericFormat
                    key="base-compensation-input"
                    value={displaySalary}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    sx={{
                      color: 'black',
                      backgroundColor: `${theme.palette.primary.main}`,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    customInput={TextField}
                    variant="standard"
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel id="employee-max-variable-comp-label">Variable Compensation</InputLabel>
                  <NumericFormat
                    key="variable-compensation-input"
                    value={variableCompensation}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      color: 'black',
                      backgroundColor: `${theme.palette.secondary.main}`,
                    }}
                    customInput={TextField}
                    variant="standard"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <CompensationChart key="compensation-chart" salary={displaySalary} variable={variableCompensation} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default CompensationPlanDetailForm
