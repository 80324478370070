export interface SortParams {
  field: string
  order: string
}

export interface PaginationParams {
  page: number
  perPage: number
}

export interface SearchParams {
  searchTerm: string
  pagination: PaginationParams
  sort: SortParams[]
}

export const generateSearchQueryParams = (params: SearchParams): [string, string][] => {
  return [
    ['searchTerm', encodeURIComponent(params.searchTerm)],
    ['page', encodeURIComponent(`${params.pagination.page}`)],
    ['perPage', encodeURIComponent(`${params.pagination.perPage}`)],
    ['sort', encodeURIComponent(params.sort.map((sort) => `${sort.field}`).join(','))],
    ['order', encodeURIComponent(params.sort.map((sort) => `${sort.order}`).join(','))],
  ]
}
