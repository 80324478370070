import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import CompensationChart from 'components/CompensationChart'
import CurrencyField from 'components/CurrencyField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SinglePeriodSelector from 'components/SinglePeriodSelector'
import { FC, useEffect, useState } from 'react'

import { Organization } from 'types/organization'
import { createIdToPeriodFromList, Period } from 'types/periods'
import { UserProfile } from 'types/user-profile'

type TemplateDetailsProps = {
  label: string
  position: string
  rootOrganizationId: string
  periodId: string
  periodProration: number
  rootPeriodId: string
  basePlus: boolean
  variablePercent: number
  minVariablePercent: number
  maxVariablePercent: number
  setLabel: (label: string) => void
  setPosition: (position: string) => void
  setRootOrganizationId: (id: string) => void
  setPeriodId: (id: string) => void
  setPeriodProration: (periodProration: number) => void
  setRootPeriodId: (id: string) => void
  setBasePlus: (basePlus: boolean) => void
  setVariablePercent: (variablePercent: number) => void
  setMinVariablePercent: (minVariablePercent: number) => void
  setMaxVariablePercent: (maxVariablePercent: number) => void
  organizationMap: Record<string, Organization>
  fiscalYears: Record<string, Period>
  periods: Period[]
  setPeriod: (period: Period) => void
  exampleSalary: number
  setExampleSalary: (exampleSalary: number) => void
  setExampleBonusAmount: (exampleBonusAmount: number) => void
  defaultVariableCap: number
  minVariableCap: number
  maxVariableCap: number
  setDefaultVariableCap: (defaultVariableCap: number) => void
  setMinVariableCap: (minVariableCap: number) => void
  setMaxVariableCap: (maxVariableCap: number) => void
  userProfile?: UserProfile
  organizationIds: string[]
  setOrganizationIds: (ids: string[]) => void
  theme: Theme
  currency: string
  currencyDecimals: number
}

const TemplateDetails: FC<TemplateDetailsProps> = ({
  label,
  position,
  rootOrganizationId,
  periodId,
  periodProration,
  rootPeriodId,
  basePlus,
  variablePercent,
  minVariablePercent,
  maxVariablePercent,
  periods,
  setLabel,
  setPosition,
  setRootOrganizationId,
  setPeriodId,
  setRootPeriodId,
  setPeriodProration,
  setBasePlus,
  setVariablePercent,
  setMinVariablePercent,
  setMaxVariablePercent,
  organizationMap,
  fiscalYears,
  setPeriod,
  exampleSalary,
  setExampleSalary,
  setExampleBonusAmount,
  defaultVariableCap,
  minVariableCap,
  maxVariableCap,
  setDefaultVariableCap,
  setMinVariableCap,
  setMaxVariableCap,
  userProfile,
  organizationIds,
  setOrganizationIds,
  theme,
  currency,
  currencyDecimals,
}) => {
  const [sliderValues, setSliderValues] = useState<number[]>([
    minVariablePercent * 100,
    variablePercent * 100,
    maxVariablePercent * 100,
  ])

  const [variableCap, setVariableCap] = useState<number[]>([
    minVariableCap * 100,
    defaultVariableCap * 100,
    maxVariableCap * 100,
  ])

  const [displayBaseCompensation, setDisplayBaseCompensation] = useState(() => {
    if (periodId && exampleSalary && variablePercent) {
      const bonusAmount = exampleSalary * variablePercent
      const baseSalary = basePlus ? exampleSalary : exampleSalary - bonusAmount
      return baseSalary * periodProration
    }
    return 0 // Default for new templates
  })

  const [displayBonusAmount, setDisplayBonusAmount] = useState(() => {
    if (periodId && exampleSalary && variablePercent) {
      const bonusAmount = exampleSalary * variablePercent
      const baseSalary = basePlus ? exampleSalary : exampleSalary - bonusAmount
      const proratedBaseSalary = baseSalary * periodProration
      return basePlus ? proratedBaseSalary * variablePercent : exampleSalary * periodProration - proratedBaseSalary
    }
    return 0 // Default for new templates
  })

  const [periodMap, setPeriodMap] = useState<Record<string, Period>>({})

  useEffect(() => {
    setPeriodMap(createIdToPeriodFromList(periods))

    if (periodId && rootPeriodId && periodMap[periodId] && periodMap[rootPeriodId]) {
      const selectedPeriod = periodMap[periodId]
      setPeriod(selectedPeriod)
      setRootPeriodId(selectedPeriod.rootPeriodId || '')
      setPeriodProration(selectedPeriod.periodProration || 1.0)
      const bonusAmount = exampleSalary * variablePercent
      const baseSalary = basePlus ? exampleSalary : exampleSalary - bonusAmount

      const proratedBaseSalary = baseSalary * (selectedPeriod.periodProration || 1.0)
      const proratedBonusAmount = basePlus
        ? proratedBaseSalary * variablePercent
        : exampleSalary * (selectedPeriod.periodProration || 1.0) - proratedBaseSalary

      setDisplayBaseCompensation(proratedBaseSalary)
      setDisplayBonusAmount(proratedBonusAmount)
    }
  }, [periods, periodId, rootPeriodId, variablePercent, basePlus, exampleSalary])

  useEffect(() => {
    setSliderValues([minVariablePercent * 100, variablePercent * 100, maxVariablePercent * 100])
    setVariableCap([minVariableCap * 100, defaultVariableCap * 100, maxVariableCap * 100])
  }, [minVariableCap, maxVariableCap, defaultVariableCap, variablePercent, minVariablePercent, maxVariablePercent])

  const handlePeriodChange = (event: string) => {
    const selectedId = event
    const selectedPeriod: Period = periodMap[selectedId]

    setPeriodId(selectedPeriod.id || '')
    setPeriod(selectedPeriod)
    setRootOrganizationId(selectedPeriod.organizationId || '')
    setRootPeriodId(selectedPeriod.rootPeriodId || '')
    setPeriodProration(selectedPeriod.periodProration || 1.0)
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setMinVariablePercent(newValue[0] / 100)
      setVariablePercent(newValue[1] / 100)
      setMaxVariablePercent(newValue[2] / 100)
      setSliderValues(newValue)

      const baseSalary = basePlus ? exampleSalary : exampleSalary * (1 - variablePercent)
      const proratedBaseSalary = baseSalary * periodProration
      const bonusAmount = basePlus ? proratedBaseSalary * variablePercent : proratedBaseSalary - baseSalary

      setExampleBonusAmount(bonusAmount)
    }
  }

  const handleCapSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setMinVariableCap(newValue[0] / 100)
      setDefaultVariableCap(newValue[1] / 100)
      setMaxVariableCap(newValue[2] / 100)
      setVariableCap(newValue)
    }
  }

  if (!userProfile || !periods) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'h4'}>Template Details</Typography>
      </Grid>
      <Grid container item xs={9} spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Name"
              fullWidth
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              placeholder="Name"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Position"
              fullWidth
              type="text"
              placeholder="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiOrganizationSelector
            userProfile={userProfile}
            selectedOrganizationIds={organizationIds}
            handleChange={(orgIds) => {
              setOrganizationIds(orgIds)
              if (orgIds.length > 0) {
                const firstOrgRoot = organizationMap[orgIds[0]]?.rootOrganizationId
                if (firstOrgRoot) {
                  setRootOrganizationId(firstOrgRoot)
                }
              }
            }}
            label="Select Teams"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Select Compensation Period</InputLabel>
            <SinglePeriodSelector
              key="period-selector"
              periods={periods}
              value={periodId}
              label=""
              onChange={handlePeriodChange}
              rootPeriodOnly={false}
              sortOrder="asc"
              style={{ marginTop: '16px' }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom>Variable Compensation</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid item xs={12}>
              <Slider
                key={'slider-variable-compensation'}
                value={sliderValues}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 100, label: '100%' },
                  { value: sliderValues[0], label: `${sliderValues[0]}%` },
                  { value: sliderValues[2], label: `${sliderValues[2]}%` },
                  { value: sliderValues[1], label: `${sliderValues[1]}%` },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>Maximum Payout</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid item xs={12}>
              <Slider
                key={'slider-variable-cap'}
                value={variableCap}
                onChange={handleCapSliderChange}
                valueLabelDisplay="auto"
                min={100}
                max={500}
                marks={[
                  { value: 100, label: '100%' },
                  { value: 500, label: '500%' },
                  { value: variableCap[0], label: `${variableCap[0]}%` },
                  { value: variableCap[2], label: `${variableCap[2]}%` },
                  { value: variableCap[1], label: `${variableCap[1]}%` },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            key={'base-plus-checkbox'}
            control={<Checkbox checked={basePlus} onChange={(e) => setBasePlus(e.target.checked)} />}
            label="Base Plus"
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyField
            label="Example Annual Base Salary"
            value={exampleSalary.toString()}
            onChange={(value) => setExampleSalary(Number(value))}
            name="exampleCompensation"
            currency={currency}
            currencyDecimals={currencyDecimals}
          />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={'h4'}>Compensation Chart</Typography>
        <CompensationChart
          key={'compensation-chart'}
          salary={displayBaseCompensation}
          variable={displayBonusAmount}
          currency={currency}
          currencyDecimals={currencyDecimals}
        />
      </Grid>
    </Grid>
  )
}

export default TemplateDetails
