import { CardContent, Divider, Grid, Typography } from '@mui/material'
import GradientPaper from 'components/cards/GradientPaper'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { Component, ComponentType, TargetDTO } from 'types/components'
import { Period, createIdLabelMap } from 'types/periods'

interface PlanTargetRowProps {
  componentId: string
  component?: Component
  target?: TargetDTO
  reviewOnly?: boolean
  periodLabel?: string
}

function formatValue(value: string | number, componentType: ComponentType): string {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return parseInt(value as string).toLocaleString()
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return parseFloat(value as string).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return `${(parseFloat(value as string) * 100).toFixed(2)}%`
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return parseFloat(value as string).toFixed(2)
    case ComponentType.COMPONENT_TYPE_BOOL:
      return value ? 'True' : 'False'
    default:
      return value.toString()
  }
}

const PlanTargetRow: React.FC<PlanTargetRowProps> = ({ componentId, component, target, periodLabel }) => {
  if (component == null || target == null) {
    return <></>
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sm={4}>
        <Typography variant="h6">{component.label}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography align={'center'}>{periodLabel ?? ''}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography align={'center'}>{formatValue(target.value, component.componentType)}</Typography>
      </Grid>
    </Grid>
  )
}

interface PlanTargetsProps {
  plan: ExpandedCompensationPlan
  period: Period
}

const PlanTargetsCard: React.FC<PlanTargetsProps> = ({ plan, period }) => {
  if (plan == null) {
    return <></>
  }

  const componentsMap = plan.components.reduce((acc: Record<string, Component>, component) => {
    if (component.id != null) {
      acc[component.id] = component
    }
    return acc
  }, {})

  // Updated to use a composite key of componentId and periodId
  const targetsComponentIdMap = plan.targets.reduce((acc: Record<string, TargetDTO>, target) => {
    const key = `${target.componentId}_${target.periodId}`
    acc[key] = target
    return acc
  }, {})

  const activeTargetComponentIds = plan.plan.variableAllocations.reduce((acc: Set<string>, table) => {
    const activeTargetsForTable = table.rows.reduce((rowAcc: Set<string>, row) => {
      if (!(row.disabled ?? false)) {
        ;(row.componentIds ?? []).forEach((id) => {
          const periodId = row.periodId // Assuming periodId is available in row
          const compositeKey = `${id}_${periodId}`
          rowAcc.add(compositeKey)
        })
      }
      return rowAcc
    }, new Set<string>())

    activeTargetsForTable.forEach((id) => acc.add(id))
    return acc
  }, new Set<string>())
  // Convert Set back to array if needed elsewhere as array
  const uniqueActiveTargetComponentIds = Array.from(activeTargetComponentIds)

  const periodIdLabelMap = createIdLabelMap(period)

  return (
    <div style={{ width: '100%' }}>
      <GradientPaper sx={{ paddingTop: 1, width: '100%' }} gradientScale={0.5}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h3">Target</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" align={'center'}>
                Period
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" align={'center'}>
                Value
              </Typography>
            </Grid>
          </Grid>
          {uniqueActiveTargetComponentIds.map((compositeId, index) => {
            const [componentId, periodId] = compositeId.split('_')
            const component = componentsMap[componentId]
            const target = targetsComponentIdMap[compositeId]
            const periodLabel = periodIdLabelMap[periodId]

            if (component === undefined || target === undefined) {
              return <></>
            }

            return (
              <div key={compositeId}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Divider />
                </div>

                <PlanTargetRow
                  componentId={componentId}
                  component={component}
                  target={target}
                  periodLabel={periodLabel}
                />
              </div>
            )
          })}
        </CardContent>
      </GradientPaper>
    </div>
  )
}

export default PlanTargetsCard
