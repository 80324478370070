import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import { ChangeEvent, FC, startTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaginationInfo } from 'types/common'
import { ComponentType, formatValue } from 'types/components'
import { KeyedObject } from 'types/root'

interface MetricsTableProps {
  selectedOrganizations: string[]
  selectedPeriods: string[]
  searchTerm: string
  periodLabelMap: Record<string, string>
  organizationLabelMap: Record<string, string>
  metrics: any
  page: number
  rowsPerPage: number
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void
}

const MetricsTable: FC<MetricsTableProps> = ({
  selectedOrganizations,
  selectedPeriods,
  searchTerm,
  periodLabelMap,
  organizationLabelMap,
  metrics,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  // table columns
  interface ColumnProps {
    id: string
    label: string
    minWidth: number
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
    format?: (row: KeyedObject) => string | boolean | JSX.Element
  }

  const columns: ColumnProps[] = [
    {
      id: 'organizationId',
      label: 'Team',
      minWidth: 170,
      format: (row: KeyedObject) => organizationLabelMap[row.organizationId],
    },
    { id: 'periodId', label: 'Period', minWidth: 170, format: (row: KeyedObject) => periodLabelMap[row.periodId] },
    { id: 'label', label: 'Name', minWidth: 170 },
    {
      id: 'value',
      label: 'Value',
      minWidth: 170,
      format: (row: KeyedObject) =>
        formatValue(
          row.componentType === ComponentType.COMPONENT_TYPE_PERCENTAGE ? Number(row.value) * 100 : row.value,
          row.componentType
        ),
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 50,
      format: (row: KeyedObject) => (
        <Box display="flex">
          <Box mx={0}>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() =>
                  startTransition(() => {
                    navigate(
                      `/admin/metrics/edit?componentId=${row.componentId}&organizationId=${row.organizationId}&periodId=${row.periodId}&userId=${row.userId || 'null'}`
                    )
                  })
                }
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
  ]

  const paginationInfo: PaginationInfo = metrics?.paginationInfo ?? {
    currentPage: 0,
    perPage: 10,
    totalItems: 0,
    totalPages: 0,
  }

  return (
    <Paper sx={{ mb: 3 }}>
      {/* table */}
      <TableContainer sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  id={'cell-col-' + (column.id || '')}
                  sx={{
                    minWidth: column.minWidth,
                    position: 'sticky !important',
                  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {metrics && metrics.metrics && metrics.metrics.length > 0 ? (
              metrics.metrics.map((row: KeyedObject, index: number) => (
                <TableRow
                  id={`table-row-${row.id || index}`}
                  sx={{ py: 3 }}
                  role="checkbox"
                  tabIndex={-1}
                  key={`table-row-${row.id || index}`}
                >
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell id={'cell-' + column.id} key={'cell-' + column.id} align={column.align}>
                        {column.format ? column.format(row) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
        <AnimateButton>
          <Tooltip title="Record Accuals">
            <Button
              variant="contained"
              startIcon={<PlusOutlined />}
              color="primary"
              onClick={() =>
                startTransition(() => {
                  navigate('/admin/components/actuals/create')
                })
              }
            >
              Record Actuals
            </Button>
          </Tooltip>
        </AnimateButton>

        {/* table pagination */}
        {metrics && metrics.metrics && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component="div"
            count={paginationInfo.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Paper>
  )
}

export default MetricsTable
